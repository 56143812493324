import React, {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import { useLocalStorage } from 'usehooks-ts';
import Cookies from 'js-cookie';

import { STORAGE_KEYS } from '@/constants/storageKeys';
import {
  BridgeAPI,
  BridgeState,
  EventName,
  IBridgeProvider,
  Store,
} from '@/bridge/types';
import { isValidOrigin, parseEventMessage } from '@/bridge/utils/parser';
import { BRIDGE_COOKIE_SESSION_ID, bridgeReducer, initialState } from './store';
import { BridgeMessagingAPI } from './bridgeMessagingAPI';

export const BridgeContext = createContext(initialState);

const initialAPI: BridgeAPI = {
  closeWebview: BridgeMessagingAPI.closeWebview,
  openUrl: BridgeMessagingAPI.openUrl,
};

export const BridgeAPIContext = createContext(initialAPI);

const BROWSER_LIST_ORIGINS = [
  'https://www.instagram.com',
  'https://www.facebook.com',
  'https://maps.google.com',
  'https://www.youtube.com',
  'https://youtu.be',
];

export const BridgeProvider = ({ children, isWebView }: IBridgeProvider) => {
  const [state, dispatch] = useReducer(bridgeReducer, {
    ...initialState,
    isWebView,
  });
  const [storage, setStorage] = useLocalStorage<BridgeState | null>(
    STORAGE_KEYS.BRIDGE,
    null
  );
  const sessionId = useRef<number>(
    Number(Cookies.get(BRIDGE_COOKIE_SESSION_ID))
  );

  useEffect(() => {
    const currentSessionId = sessionId.current;

    let payload = { ...initialState, isWebView };

    if (storage && currentSessionId) {
      if (storage.sessionId === currentSessionId) {
        payload = storage;
      } else {
        setStorage(null);
      }
    }

    dispatch({ type: Store.InternalEventName.SyncStorage, payload });
  }, []);

  useEffect(() => {
    if (storage) return;

    if (state.isSetConfig && state.isConnected) {
      BridgeMessagingAPI.handshake();
    } else if (state.isConnected) {
      const config = {
        originBrowserList: BROWSER_LIST_ORIGINS,
      };

      BridgeMessagingAPI.setConfig(config);
    } else {
      BridgeMessagingAPI.connecting();
    }
  }, [storage, state]);

  const onHandshake = useCallback(() => {
    if (sessionId.current) {
      state.sessionId = sessionId.current;
    }

    state.isWebView = true;

    setStorage(state);
  }, [state]);

  useEffect(() => {
    const callback = (nativeEvent: MessageEvent<string | object>) => {
      try {
        const message = nativeEvent.data;
        const data = parseEventMessage(message);

        if (!data || !data.params.origin) return;

        const { origin, ...payload } = data.params;

        if (!isValidOrigin(origin)) return;

        if (data.eventName === EventName.Handshake) onHandshake();

        dispatch({
          type: data.eventName,
          payload: payload as keyof Store.Payload,
        });
      } catch (error) {
        console.log(error);
      }
    };

    return BridgeMessagingAPI.subscribe(callback);
  }, [onHandshake]);

  return (
    <BridgeContext.Provider value={state}>
      <BridgeAPIContext.Provider value={initialAPI}>
        {children}
      </BridgeAPIContext.Provider>
    </BridgeContext.Provider>
  );
};
