import { AdStatus } from '@/types';

const AD = '/elanlar';
const AUTH = '/authentications';
const PROFILE = '/profile';
const HELP = '/help';
const PAGES = '/pages';
const MESSAGES = '/messages';

export const ROUTES = {
  home: '/',
  bookmarks: '/bookmarks',
  ad: AD,
  vipAds: `${AD}/vip`,
  newAd: `${AD}/new`,
  login: `${AUTH}/new`,
  messages: MESSAGES,
  messagesPurchase: `${MESSAGES}#purchase`,
  messagesSale: `${MESSAGES}#sale`,
  shops: '/shops',
  profile: PROFILE,
  profileAds: (adStatus: AdStatus) => `${PROFILE}/${adStatus}`,
  settings: `${PROFILE}/settings`,
  editProfile: `${PROFILE}/edit`,
  business: `${PROFILE}/business`,
  buyPlan: `${PROFILE}/business/buy-plan`,
  transactions: `${PROFILE}/transactions`,
  accountTxs: `${PROFILE}/transactions#account`,
  adsTxs: `${PROFILE}/transactions#ads`,
  limits: `${PROFILE}/ad-placement-limits`,
  cards: `${PROFILE}/cards`,
  addBalance: `${PROFILE}/published#replenishment`,
  help: HELP,
  faq: `${HELP}/popular_questions`,
  top10Questions: `${PAGES}/help`,
  rules: `${PAGES}/rules`,
  terms: `${PAGES}/terms-and-conditions`,
  proposal: `${PAGES}/proposal`,
  paidServices: `${PAGES}/paid-services`,
  privacy: `${PAGES}/privacy`,
  limitsByCategory: `${PAGES}/limits_by_category`,
  about: `${PAGES}/about`,
  packages: `${PAGES}/packages`,
  advertising: `${PAGES}/advertising`,
  advertising_landing: (lang: string) =>
    `https://${lang}ads.digit.az/?utm_source=tapaz&utm_medium=referral&utm_campaign=tapaz_referral&utm_content=burger`,
};

// Routes that are ready for production, others will return 404 in order to show the old FE version
export const READY_ROUTES_FOR_PRODUCTION = [ROUTES.home, ROUTES.bookmarks];
