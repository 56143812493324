import PubNub, { FileEvent, MessageEvent, SubscribeParameters } from 'pubnub';
import { PubNubStore } from '@/store/usePubNubStore';
import { formatLastMessage } from '@/utils/pubnub/formatLastMessage';
import { ChannelMessage } from '@/utils/pubnub/pubnub.types';

export const handleNewChatFirstMessage = (
  pubNubClient: PubNub,
  event: MessageEvent | FileEvent,
  lastMessagesMap: PubNubStore['lastMessagesMap'],
  partialUpdateLastMessagesMap: PubNubStore['partialUpdateLastMessagesMap']
) => {
  const channel =
    (event?.userMetadata as { channel: string })?.channel || event?.channel;

  const lastReadTimetoken =
    lastMessagesMap?.[channel]?.lastReadTimetoken || null;

  if (event.channel.includes('notif.') && lastMessagesMap?.[channel]) return;

  if (!lastMessagesMap?.[channel]) {
    const param = { channels: [channel] } as SubscribeParameters;
    pubNubClient.subscribe(param);
  }

  partialUpdateLastMessagesMap(
    channel,
    formatLastMessage(pubNubClient, event as ChannelMessage, lastReadTimetoken)
  );
};
