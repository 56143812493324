import PubNub from 'pubnub';
import { notifyBugsnagError } from '@/utils/pubnub/notifyBugsnagError';

export const getPubnubPrivateKey = async (pubnub: PubNub) => {
  try {
    const { data } = await pubnub.objects.getUUIDMetadata({
      uuid: `${pubnub.getUUID()}-ro`,
    });

    return data.custom?.privateKey;
  } catch (e) {
    notifyBugsnagError(e, pubnub);
    return null;
  }
};
