import { DefaultTheme, ThemeProvider } from 'styled-components';
import { PropsWithChildren } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { STORAGE_KEYS } from '@/constants/storageKeys';
import { defaultTheme } from '@/styles/defaultTheme';
import { useBridgeState } from '@/bridge/hooks';

interface IThemeProviderWrapper extends PropsWithChildren {
  isWebView: boolean;
}

export const ThemeProviderWrapper = (props: IThemeProviderWrapper) => {
  const [theme] = useLocalStorage<DefaultTheme>(
    STORAGE_KEYS.THEME,
    defaultTheme
  );
  const bridgeState = useBridgeState();
  let { tabBarHeight } = theme;

  const isWebView = props.isWebView || bridgeState.isWebView;

  if (isWebView) tabBarHeight = theme.tabBarHeight + bridgeState.insets.bottom;

  return (
    <ThemeProvider
      theme={{
        ...theme,
        isWebView,
        tabBarHeight,
      }}
    >
      {props.children}
    </ThemeProvider>
  );
};
