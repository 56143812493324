/* eslint-disable @typescript-eslint/no-namespace */

import { EventName, ValueOf } from '@/bridge/types/events';
import { PaymentStatusData } from '@/bridge/types/payment';

type Insets = {
  top: number;
  bottom: number;
  left: number;
  right: number;
};

export interface InitialState {
  isWebView: boolean;
  isConnected: boolean;
  isSetConfig: boolean;
  bridgeVersion: number;
  insets: Insets;
}

export interface BridgeState extends InitialState {
  sessionId?: number;
}

export namespace Store {
  export enum InternalEventName {
    SyncStorage = 'syncStorage',
  }

  export type Payloads = {
    [InternalEventName.SyncStorage]: InitialState | BridgeState;
    [EventName.Connecting]: object;
    [EventName.SetConfig]: { bridgeVersion: number; insets: Insets };
    [EventName.Handshake]: object;
    [EventName.PaymentStatus]: PaymentStatusData;
  };

  export type Payload = ValueOf<Payloads>;

  export type ActionGenerator<E extends keyof Payloads> = {
    type: E;
    payload: Payloads[E];
  };

  export type Actions = {
    [InternalEventName.SyncStorage]: ActionGenerator<InternalEventName.SyncStorage>;
    [EventName.Connecting]: ActionGenerator<EventName.Connecting>;
    [EventName.SetConfig]: ActionGenerator<EventName.SetConfig>;
    [EventName.Handshake]: ActionGenerator<EventName.Handshake>;
    [EventName.PaymentStatus]: ActionGenerator<EventName.PaymentStatus>;
  };

  export type Action = ValueOf<Actions>;
}
