import { BridgeState, InitialState, Store, EventName } from '@/bridge/types';

export const BRIDGE_COOKIE_SESSION_ID = '_tapaz_bridge_session_id';

export const initialState: InitialState = {
  isWebView: false,
  isConnected: false,
  isSetConfig: false,
  bridgeVersion: 0,
  insets: { top: 0, bottom: 0, left: 0, right: 0 },
};

export const bridgeReducer = (state: BridgeState, action: Store.Action) => {
  const { type, payload } = action;

  switch (type) {
    case Store.InternalEventName.SyncStorage: {
      return {
        ...state,
        ...payload,
      };
    }
    case EventName.Connecting: {
      return {
        ...state,
        isConnected: true,
      };
    }
    case EventName.SetConfig: {
      return {
        ...state,
        ...payload,
        isSetConfig: true,
      };
    }
    case EventName.Handshake: {
      return {
        ...state,
        ...payload,
        isWebView: true,
      };
    }
    default:
      return state;
  }
};
