/* eslint-disable @typescript-eslint/no-namespace */

export enum PaymentStatus {
  success = 'success',
  failure = 'failure',
}

enum Gateways {
  Azericard = 'azericard',
}

namespace GenericPaymentStatusData {
  export type Success = {
    status: PaymentStatus.success;
    amount: string;
    updated_at: string;
    payment_id: string;
    message: string;
    target_id: string;
    service_name: string;
    service_id: string;
    payment_source: string;
  };

  export type Failure = {
    status: PaymentStatus.failure;
  };
}

namespace AzericardPaymentStatusData {
  export type Success = {
    amount: string;
    currency: string;
    merchant_name: string;
    order_id: string;
    rrn: string;
    status: PaymentStatus.success;
    gateway: Gateways.Azericard;
  };

  export type Fault = {
    reason: string;
    bank_response_code: string;
    status: PaymentStatus.failure;
    gateway: Gateways.Azericard;
  };

  export type Duplicate = {
    amount: string;
    currency: string;
    order_id: string;
    rrn: string;
    merchant_name: string;
    status: PaymentStatus.failure;
    gateway: Gateways.Azericard;
  };

  export type Decline = {
    rrn: string;
    bank_response_code: string;
    status: PaymentStatus.failure;
    gateway: Gateways.Azericard;
  };
}

export type PaymentStatusData =
  | GenericPaymentStatusData.Success
  | GenericPaymentStatusData.Failure
  | AzericardPaymentStatusData.Success
  | AzericardPaymentStatusData.Fault
  | AzericardPaymentStatusData.Duplicate
  | AzericardPaymentStatusData.Decline;
