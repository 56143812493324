import { useEffect } from 'react';
import PubNub, { FileEvent, MessageActionEvent, MessageEvent } from 'pubnub';
import { usePubNubStore } from '@/store/usePubNubStore';
import { restClient } from '@/config/rest-client';
import { useAuth } from '@/hooks/useAuth';
import { useNewCSRFToken } from '@/hooks/useNewCSRFToken';
import { ChatSettingResponse } from '@/utils/pubnub/pubnub.types';
import { onMessageAction } from '@/utils/pubnub/onMessageAction';
import { handleNewChatFirstMessage } from '@/utils/pubnub/handleNewChatFirstMessage';
import { getPubnubPrivateKey } from '@/utils/pubnub/getPubnubPrivateKey';
import { getMemberships } from '@/utils/pubnub/getMemberships';
import { notifyBugsnagError } from '@/utils/pubnub/notifyBugsnagError';

export const usePubNubInit = () => {
  const [
    setClient,
    setNotificationChannelName,
    lastMessagesMap,
    setLastMessagesMap,
    partialUpdateLastMessagesMap,
  ] = usePubNubStore(state => [
    state.setClient,
    state.setNotificationChannelName,
    state.lastMessagesMap,
    state.setLastMessagesMap,
    state.partialUpdateLastMessagesMap,
  ]);

  const { isAuthenticated } = useAuth();
  const CSRFToken = useNewCSRFToken();

  useEffect(() => {
    if (!isAuthenticated) return;

    let pubNubClient: PubNub;
    const doInitialChatSetup = async () => {
      try {
        const { data } = await restClient().get<ChatSettingResponse>(
          '/api/v1/chat.json',
          { headers: { 'X-Csrf-Token': CSRFToken } }
        );

        if (!data) {
          return new Error(`Failed to fetch chat settings`);
        }

        pubNubClient = new PubNub({
          origin: 'digitaz.pubnub.com',
          publishKey: data.publish_key,
          subscribeKey: data.subscribe_key,
          authKey: data.chat_auth_key,
          userId: data.chat_uid,
        });

        pubNubClient.addListener({
          messageAction: (messageActionEvent: MessageActionEvent) => {
            onMessageAction(messageActionEvent, partialUpdateLastMessagesMap);
          },
          message: (messageEvent: MessageEvent) => {
            handleNewChatFirstMessage(
              pubNubClient,
              messageEvent,
              lastMessagesMap,
              partialUpdateLastMessagesMap
            );
          },
          file: (fileEvent: FileEvent) => {
            handleNewChatFirstMessage(
              pubNubClient,
              fileEvent,
              lastMessagesMap,
              partialUpdateLastMessagesMap
            );
          },
        });

        const privateKey = await getPubnubPrivateKey(pubNubClient);

        if (!privateKey) {
          const error = new Error('PrivateKey is not available');
          // @ts-ignore
          error.status = { category: 'PrivateKey' };
        }

        await getMemberships(
          pubNubClient,
          data.notification_channel_name,
          setLastMessagesMap
        );

        setNotificationChannelName(data.notification_channel_name);
        setClient(pubNubClient);
      } catch (e) {
        notifyBugsnagError(e, pubNubClient);
        return new Error(
          `[usePubNubInit - doInitialChatSetup error]: Failed doInitialChatSetup. ${e}`
        );
      }
    };

    doInitialChatSetup();
  }, [isAuthenticated]);
};
