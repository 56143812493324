import Cookies from 'js-cookie';

import { STORAGE_KEYS } from '@/constants/storageKeys';
import { BRIDGE_COOKIE_SESSION_ID } from '@/bridge/store';
import { EventName, Response } from '@/bridge/types';

interface ReactNativeWebViewMessage {
  postMessage(message: string): void;
}

declare global {
  interface Window {
    ReactNativeWebView?: ReactNativeWebViewMessage;
  }
}

export class BridgeMessagingAPI {
  protected static get origin() {
    return window.location.origin;
  }

  static subscribe(callback: (this: Window, ev: MessageEvent<string>) => void) {
    window.addEventListener('message', callback, true);
    return () => window.removeEventListener('message', callback);
  }

  static sendEventToReactNative({ eventName, params }: Response.Event) {
    try {
      if (!window.ReactNativeWebView?.postMessage) {
        return null;
      }

      window.ReactNativeWebView?.postMessage(
        JSON.stringify({ eventName, params })
      );
    } catch (error) {
      console.log(error);
      throw new Error(`Something went wrong during ${eventName} execution`);
    }
  }

  static connecting = () => {
    BridgeMessagingAPI.sendEventToReactNative({
      eventName: EventName.Connecting,
      params: { origin: BridgeMessagingAPI.origin },
    });
  };

  static handshake = () => {
    BridgeMessagingAPI.sendEventToReactNative({
      eventName: EventName.Handshake,
      params: { origin: BridgeMessagingAPI.origin },
    });
  };

  static closeWebview(this: void) {
    BridgeMessagingAPI.sendEventToReactNative({
      eventName: EventName.CloseWebview,
      params: { origin: BridgeMessagingAPI.origin },
    });

    localStorage.removeItem(STORAGE_KEYS.BRIDGE);
    Cookies.remove(BRIDGE_COOKIE_SESSION_ID);
  }

  static setConfig(
    this: void,
    config: Omit<Response.Params[EventName.SetConfig], 'origin'>
  ) {
    BridgeMessagingAPI.sendEventToReactNative({
      eventName: EventName.SetConfig,
      params: { ...config, origin: BridgeMessagingAPI.origin },
    });
  }

  static openUrl(this: void, url: string) {
    BridgeMessagingAPI.sendEventToReactNative({
      eventName: EventName.OpenUrl,
      params: { url, origin: BridgeMessagingAPI.origin },
    });
  }
}
